import { RequestConfig } from 'umi';
import { decamelizeKeys } from 'humps';
import { local } from '@/utils/storage';
import { ServerStyleSheets } from '@mui/styles';
import ReactDOMServer from 'react-dom/server';

/**
 * Decamelize Request Params Keys
 * @param url
 * @param options
 */
const decamelizeParams = (url: string, options: any) => {
  const { currentLang = globalThis.initLanguage } =
    local.getItem('globalState') || {};
  const { params, data, escapeDecamelize = false } = options;

  return {
    url,
    options: {
      ...options,
      params: escapeDecamelize
        ? { ...params, ...(currentLang && { lang: currentLang }) }
        : {
            ...decamelizeKeys(params),
            ...(currentLang && { lang: currentLang }),
          },
      data: escapeDecamelize ? data : decamelizeKeys(data),
    },
  };
};

export const request: RequestConfig = {
  prefix: API_URL,
  errorHandler: (error: any) => {
    throw error;
  },
  requestInterceptors: [decamelizeParams],
};

export const ssr = {
  modifyGetInitialPropsCtx: async (ctx: any) => {
    if (ctx.isServer) {
      const initLang =
        ctx?.history?.location?.pathname?.split('/')?.[3] === 'en'
          ? 'en-US'
          : 'id-ID';

      globalThis.isMobile = ctx.isMobile;
      globalThis.initLanguage = ctx.initLanguage || initLang;
    }

    return ctx;
  },
};

export function rootContainer(Container: any) {
  const sheets = new ServerStyleSheets();
  const html = ReactDOMServer.renderToString(sheets.collect(Container));
  const css = sheets.toString();
  return Container;
}
